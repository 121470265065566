import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const ZapisKonferencij = () => {
  return (
    <>
      <Helmet>
        <title>Запись конференций в Москве цена от студии Elandic</title>
        <meta name="description" content="Запись конференций цена в Москве услуги под ключ от студии Elandic. Заказать услуги профессиональной съемки пресс конференций стоимость от видеопродакшена." />
        <meta name="keywords" content="запись конференции, съемка, пресс конференций" />
      </Helmet>
      <MainPage>
        <SeoText title='Запись конференций '>
          <p>Ваша конференция – это важное событие, требующее качественной и профессиональной записи. Студия Elandic предлагает комплексные услуги по съемке конференций в Москве, помогая вам сохранить ценный контент и поделиться им с широкой аудиторией.</p>

          <p>Мы понимаем, насколько важно для вас, чтобы запись вашей конференции была безупречной. Наши операторы – настоящие профессионалы, обладающие опытом работы с различными форматами мероприятий, используют современное оборудование, гарантируя высокое качество видео с разрешением. Мы также обеспечиваем четкую и чистую запись звука, используя профессиональную аппаратуру и микрофоны, подходящие для различных типов конференций.</p>

          <p>Наши видеомонтажеры превратят отснятый материал в динамичный и интересный фильм, который будет удобно смотреть и легко воспринимать. Хотите, чтобы ваша конференция стала доступной для широкой аудитории? Мы организуем трансляцию в прямом эфире на различных платформах, обеспечивая максимальный охват зрителей.</p>

          <p>Пресс-конференция – это важный инструмент для формирования позитивного имиджа. Мы предлагаем профессиональную съемку пресс-конференций, которая позволит вам максимально эффективно донести вашу информацию до публики.</p>

          <p>Мы предлагаем гибкие цены на услуги по съемке конференций, учитывая ваши индивидуальные потребности. Стоимость зависит от длительности конференции, количества камер, используемого оборудования и прочих факторов.</p>

          <p>Наши специалисты имеют богатый опыт работы в сфере съемки конференций и пресс-конференций. Мы используем только современное профессиональное оборудование, что гарантирует высокое качество съемки. </p>

          <p>Мы готовы учесть все ваши пожелания и разработать индивидуальное предложение под ваши нужды, а также предлагаем гибкую систему цен, что делает наши услуги доступными для всех.</p>

          <p>Доверьте съемку вашей конференции профессионалам студии Elandic. Мы гарантируем вам высокое качество видео, отличную звукозапись, а также индивидуальный подход к каждой задаче. Свяжитесь с нами, чтобы заказать услуги и получить бесплатную консультацию.</p>


        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(ZapisKonferencij);
